<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <!-- <form action="#" @submit.prevent="submit"> -->
        <form action="#" @submit.prevent="showModal({ type: 'submit' })">
          <div class="form-body">
            <!-- <h3 class="card-title">Tindak Lanjut Surat</h3>
            <hr /> -->

            <div class="form-body">
              <h3 class="box-title">Tindak Lanjut Surat</h3>
              <hr class="m-t-0 m-b-40" />

              <div class="row">
                <div class="col-md-12">
                  <wysiwyg v-model="myHTML" />
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label :class="['control-label']"
                      >{{ $t("file") }} {{ $t("attachment") }}</label
                    >
                    <h6>
                      File Lampiran dalam format PDF atau JPG/JPEG/PNG dengan
                      ukuran maksimal 20MB
                    </h6>
                    <h6 v-if="!files.length">{{ $t("no_file") }}</h6>
                    <table v-else class="table table-stripped">
                      <tr>
                        <th>{{ $t("file") }}</th>
                        <th>{{ $t("action") }}</th>
                      </tr>
                      <tr v-for="file in files" v-bind:key="file.filename">
                        <td>
                          {{
                            file.filepath !== undefined
                              ? file.filename
                              : file.name
                          }}
                        </td>
                        <td>
                          <span v-if="file.filepath !== undefined">
                            <a
                              class="btn btn-success text-white"
                              @click="download(file.uuid, file.filename)"
                              ><i class="fa fa-download text-white"></i>
                            </a>
                            <a
                              class="btn btn-info text-white"
                              @click="prev(file.uuid)"
                              ><i class="fa fa-eye text-white"></i>
                            </a>
                          </span>
                          <i
                            ><b>{{ $t("not_saved") }} &nbsp;&nbsp;</b></i
                          >
                          <a
                            class="btn btn-danger text-white"
                            @click="removeAttachment(file.uuid)"
                          >
                            <i class="fa fa-trash-o text-white"></i>
                          </a>
                        </td>
                      </tr>
                    </table>
                    <Gallery :uploader="uploader" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="m-t-40" />

          <div class="form-actions">
            <button
              type="button"
              @click="showModal({ type: 'submit' })"
              class="btn btn-success"
              title="Submit"
            >
              Submit</button
            >&nbsp;
            <button
              type="button"
              @click="showModal({ type: 'cancel' })"
              class="btn btn-inverse"
              title="Cancel"
            >
              Cancel
            </button>
          </div>
        </form>
        <ModalDefault
          v-show="isVisible"
          :title="modalTitle"
          :content="modalContent"
          :action="ActionChangeable"
          :confirmation="isConfirmation"
          @close="onClose"
        />
      </div>
    </div>

    <hr />
    <!-- <div class="card">
      <h4>Keterangan</h4>
      <table>
        <tr>
          <td>Konfirmasi</td>
          <td>Untuk mengkonfirmasi surat</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>Retur</td>
          <td>Untuk meretur surat</td>
        </tr>
      </table>
    </div> -->
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import Modal from "@/components/modal/modal.vue";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    Modal,
    Input,
    Datepicker,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.documentIn;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    const uploader = this.fineUploader(this, "document_in");

    return {
      uploader,
      //   files: [],
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      disabledDates: {
        to: new Date(Date.now()),
      },
      isModalVisible: false,
      payload: {
        // confirm: 1,
        myHtml: "",
      },
      myHTML: "",
      myFile: [],
      files: [],
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      disabledButton: false,
    };
  },
  async mounted() {
    this.initialize();
    // this.getAllReference();
    const user = JSON.parse(localStorage.getItem("user"));
    if (!this.editMode) {
      this.payload.unitkerja = [{ value: user.unit_id, text: user.unit }];
    }

    console.log(this.payload.tanggal_surat);
  },

  watch: {
    $route: function (search) {
      if (this.editMode) {
        axios.get(`/document_in/${this.$route.params.id}`).then((response) => {
          const state = {
            loaded: true,
          };
          this.$store.commit("documentIn/STATE", state);
          this.edit(response.data.data);
          this.isModalVisible = false;
        });
      }
    },
  },

  methods: {
    onClose() {
      this.isVisible = false;
    },
    // async ambilTanggal() {
    //   this.disabledDates.to = this.payload.tanggal_surat;
    // },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "submit") {
        this.modalTitle = "Simpan Data?";
        this.modalContent = "<p>Apakah anda yakin ingin menyimpan data?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.submit;
      } else if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/document_in/${this.$route.params.id}`)
            .then((response) => {
              const state = {
                loaded: true,
              };
              this.$store.commit("documentIn/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("documentIn/onAdd");
          axios.get(`/document_in/new`).then((response) => {
            this.payload.agenda = response.data.data.agenda_number;
            this.payload.tanggal_surat = new Date();
            this.payload.tanggal_diterima = new Date();
          });
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      //   this.payload = {
      //     unitkerja: data.receiver,
      //     agenda: data.agenda_number,
      //     tanggal_surat: new Date(data.tanggal_surat),
      //     tanggal_diterima: new Date(data.tanggal_diterima),
      //     sifat: data.document_sifat,
      //     jenis: data.document_jenis,
      //     perihal: data.subject,
      //     nama: data.document_name,
      //     nomorsurat: data.nomorsurat,
      //     pages: data.pages,
      //     myHTML: data.description,
      //     files: data.files,
      //     jumlah: data.jumlah,
      //     lampiran: data.document_lampiran,
      //     asrat: data.document_asrat,
      //     kodrat: data.document_kodrat,
      //     tas: data.document_tas,
      //     kecrat: data.document_kecrat,
      //     ketrat: data.document_ketrat,
      //     redaksi: data.document_redaksi
      //   };
      //   this.files = data.files;
      //   this.dropdownSifatSurat.value = data.document_asrat;
      //   this.dropdownKecepatanSurat.value = data.document_kecrat;
      //   this.dropdownLampiran.value = data.document_lampiran;
      //   this.dropdownJenisSurat.value = data.document_jenis;
      //   this.dropdownTipeAsalSurat.value = data.document_tas;
      //   this.dropdownAsalSurat.value = data.document_asrat;
      //   this.dropdownKodeSurat.value = data.document_kodrat;
      //   this.dropdownKeteranganSurat.value = data.document_ketrat;
      //   this.dropdownRedaksi.value = data.document_redaksi;
      //   this.dropdownUnitKerja.value = data.receiver;
      //   this.myHTML = data.description;
    },
    goBack() {
      this.$router.push("/dispositionIn");
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onDelete() {
      this.isModalVisible = false;
    },
    openModal() {
      this.isModalVisible = true;
    },
    submit() {
      console.log(this);
      const payload = {
        // confirm: this.payload.confirm,
        description: this.myHTML,
        files: this.files,
      };
      const data = JSON.stringify(payload);
      // const data = payload;
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("documentIn/submitLanjuti", {
              data,
              id: this.$route.params.id,
            });
          } else {
            this.$store.dispatch("documentIn/submitLanjuti", {
              data,
              id: this.$route.params.id,
            });
          }
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>
<style lang="scss">
.vdp-datepicker {
  &.fullscreen-when-on-mobile {
    @media (max-width: 767px) {
      position: static;
      .vdp-datepicker__calendar {
        position: fixed;
        top: 50%;
        left: 5%;
        margin-top: -25%;
        width: 90%;
      }
    }
  }
}
</style>
